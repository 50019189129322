import { css } from 'lit';
const cardGlobalStyles = css `
  .card {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 16px;
    margin: 16px;
  }
`;
export default cardGlobalStyles;
