var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LitElement } from 'lit';
import { routes } from './routes.js';
import AppEvents from '../app-events.js';
import { appendParamsObjToPath, decodeQuerystringValues, getRouteByPath } from './router-util.js';
import { getSessionUser } from '../session/session.js';
/**
 * @param {string} path
 * @param {Object<string, *>} params
 * */
export function go(path, params = null) {
    if (getRouteByPath(path, routes)) {
        window.history.pushState(null, null, params ? appendParamsObjToPath(path, params) : path);
        window.dispatchEvent(new CustomEvent(AppEvents.ROUTE_CHANGE));
    }
}
/**
 * @param {Route} routeObj
 * @returns {Route | null}
 * */
function checkRoutePermissions(routeObj) {
    /** @type {PermissionParams} */
    const options = {
        sessionUser: getSessionUser(),
    };
    let redirect = null;
    // @ts-ignore
    routeObj.permissions.forEach((p) => {
        redirect = p(options);
        if (redirect)
            return;
    });
    return redirect;
}
const componentLoader = (/** @type {string} */ pathname) => import(`./../views/${pathname}.js`);
/**
 * @param {typeof LitElement} SuperClass
 */
export default (SuperClass) => {
    var _a;
    return _a = class extends SuperClass {
            constructor() {
                super();
                // Handle direct url routing (including initial page load)
                window.addEventListener('load', () => this.urlChange(), false);
                // Handle back and forward button events
                window.addEventListener('popstate', () => this.urlChange(), false);
                /** @type {*} */
                this.history = [];
            }
            connectedCallback() {
                super.connectedCallback();
                window.addEventListener(AppEvents.ROUTE_CHANGE, () => this.urlChange(), false);
            }
            urlChange() {
                var _a;
                return __awaiter(this, void 0, void 0, function* () {
                    if (this.backArrowOverride)
                        this.backArrowOverride = null;
                    const { pathname, search } = window.location;
                    if (pathname === '/')
                        return go(routes.MAP.path);
                    const routeObj = getRouteByPath(pathname, routes);
                    const redirectRoute = checkRoutePermissions(routeObj);
                    if (!routeObj || redirectRoute)
                        return go(redirectRoute.path);
                    this.showNav = (routeObj === null || routeObj === void 0 ? void 0 : routeObj.showNav) && !!((_a = getSessionUser()) === null || _a === void 0 ? void 0 : _a.userId);
                    this.showHeader = (routeObj === null || routeObj === void 0 ? void 0 : routeObj.showHeader) == true;
                    this.showBackArrow = (routeObj === null || routeObj === void 0 ? void 0 : routeObj.showBackArrow) == true;
                    this.showHeaderQuickNav = routeObj === null || routeObj === void 0 ? void 0 : routeObj.showHeaderQuickNav;
                    this.backArrowLocation = routeObj === null || routeObj === void 0 ? void 0 : routeObj.backArrowLocation;
                    yield componentLoader(routeObj.componentPath).catch((err) => {
                        console.error(err);
                    });
                    const context = { params: decodeQuerystringValues(search) };
                    this.history = [...this.history, Object.assign(Object.assign({}, routeObj), { context })];
                    this.history.length > 10 && (this.history.length = 10);
                    this.updateUI(routeObj, context);
                });
            }
            /**
             * @param {Route} nextView
             * @param {ContextParams} context
             * */
            updateUI(nextView, context) {
                var _a;
                return __awaiter(this, void 0, void 0, function* () {
                    const component = /** @type {RouteContainerComponent} */ (document.createElement(nextView.componentName));
                    try {
                        // @ts-ignore
                        if (component.routeEnter)
                            yield component.routeEnter({ nextView, context });
                    }
                    catch (error) {
                        console.error(error);
                    }
                    if (component.onGlobalsUpdate) {
                        component.disconnectGlobals = window.Globals.onUpdate((_a = component === null || component === void 0 ? void 0 : component.onGlobalsUpdate) === null || _a === void 0 ? void 0 : _a.bind(component));
                    }
                    const slot = this.shadowRoot.querySelector('slot');
                    slot.innerHTML = '';
                    slot.append(component);
                });
            }
        },
        _a.properties = {
            showNav: { type: Boolean },
            showHeader: { type: Boolean },
            showBackArrow: { type: Boolean },
            backArrowLocation: { type: String },
            backArrowOverride: { type: String },
            showHeaderQuickNav: { type: Boolean },
            history: { type: Array },
        },
        _a;
};
(function () {
    let blockPopstateEvent = document.readyState != 'complete';
    window.addEventListener('load', () => 
    // The timeout ensures that popstate-events will be unblocked right
    // after the load event occured, but not in the same event-loop cycle.
    setTimeout(function () {
        blockPopstateEvent = false;
    }, 0), false);
    window.addEventListener('popstate', (evt) => {
        if (blockPopstateEvent && document.readyState == 'complete') {
            evt.preventDefault();
            evt.stopImmediatePropagation();
        }
    }, false);
})();
