var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _Globals_state;
// @ts-nocheck
import AppEvents from './app-events.js';
const SESSION_GLOBALS = 'ejm-app-session-globals';
export function storeSessionGlobals(globals) {
    window.sessionStorage.setItem(SESSION_GLOBALS, JSON.stringify(globals));
}
export function getSessionGlobals() {
    const stored = window.sessionStorage.getItem(SESSION_GLOBALS);
    return stored && JSON.parse(stored);
}
function hiddenFilter({ hidden }) {
    return !hidden;
}
function getServices(orgs) {
    return (orgs
        // hide any hidden orgs and their services
        .filter(hiddenFilter)
        .map((org) => {
        return org.services.filter(hiddenFilter).map((service) => {
            service.org = org.name;
            return service;
        });
    })
        .flat());
}
function getServicesByTags(orgs) {
    return getServices(orgs).reduce((acc, service) => {
        service.categoryTags.forEach((tag) => {
            tag = tag.toLowerCase();
            if (!acc[tag])
                acc[tag] = [];
            acc[tag].push(service);
        });
        return acc;
    }, {});
}
export default class Globals {
    constructor(init = getSessionGlobals()) {
        _Globals_state.set(this, {
            orgs: [],
            services: [],
            servicesByTag: {},
        });
        const orgs = (init === null || init === void 0 ? void 0 : init.orgs) || [];
        this.update({
            orgs,
        });
        __classPrivateFieldGet(this, _Globals_state, "f");
    }
    get orgs() {
        return __classPrivateFieldGet(this, _Globals_state, "f").orgs;
    }
    /** @type {ResourceOrgService[]} */
    get services() {
        return __classPrivateFieldGet(this, _Globals_state, "f").services;
    }
    get servicesByTag() {
        return __classPrivateFieldGet(this, _Globals_state, "f").servicesByTag;
    }
    update({ orgs = __classPrivateFieldGet(this, _Globals_state, "f").orgs }) {
        const services = getServices(orgs);
        const servicesByTag = getServicesByTags(orgs);
        __classPrivateFieldSet(this, _Globals_state, {
            orgs,
            services,
            servicesByTag,
        }, "f");
        storeSessionGlobals(__classPrivateFieldGet(this, _Globals_state, "f"));
        this.dispatchUpdate();
    }
    dispatchUpdate() {
        window.dispatchEvent(new CustomEvent(AppEvents.GLOBALS_UPDATED, {
            detail: __classPrivateFieldGet(this, _Globals_state, "f"),
        }));
    }
    /**
     * @function onUpdate
     * @description Adds an event listener for global updates
     * @param {(e: CustomEvent) => void} callback - The function to be called on update event
     * @returns {Function} - Cleanup function to remove the event listener when called
     */
    onUpdate(callback) {
        window.addEventListener(AppEvents.GLOBALS_UPDATED, callback);
        return () => window.removeEventListener(AppEvents.GLOBALS_UPDATED, callback);
    }
}
_Globals_state = new WeakMap();
