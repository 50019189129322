import { LitElement, html, css } from 'lit';
import { go } from './router/router-mixin.js';
import { routes } from './router/routes.js';
import { geotagIcon, houseIcon, megaphoneIcon } from './styles/icons/object-icons.js';
class MobileNavBarElement extends LitElement {
    constructor() {
        super();
        this.navOpen = false;
        this.expanded = false;
        this.menuOptions = [
            {
                name: 'Map',
                icon: geotagIcon,
                path: routes.MAP.path || routes.RESOURCES.path,
            },
            {
                name: 'Announcements',
                icon: megaphoneIcon,
                path: routes.ANNOUNCEMENTS.path,
            },
        ];
    }
    render() {
        return html `
      <div class="main">
        <div class="nav">
          ${this.menuOptions.map((option) => html `<a
                style="fill: ${window.location.pathname === option.path ? 'var(--app-primary-color)' : 'grey'};"
                href=${option.path}
                >${option.icon}</a
              >`)}
        </div>
      </div>
    `;
    }
}
MobileNavBarElement.styles = css `
    :host {
      --nav-button-right: 30px;
      --nav-button-bottom: 45px;
      --nav-button-width: 70px;
      --nav-button-radius: 70px;
    }
    .nav {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 64px;
      background-color: var(--app-white);
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      gap: 16px;
      font-size: 1.8em;
      align-items: center;
      border-top: 2px solid lightgrey;
      cursor: pointer;
      z-index: 9999;
    }
    .main {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .expanded {
      visibility: visible;
      width: 70%;
      height: calc(var(--nav-button-width) + 6px);
      padding-right: var(--nav-button-width);
      background-color: var(--app-white);
      border-radius: var(--nav-button-radius);
      border: 1px solid #e0e0e0;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
      transition: visibility 5s ease;
      transition: width 0.3s ease;
      z-index: 100;
    }
    .expanded > a {
      /** Delay showing a tags for a bit */
      visibility: visible;
      transition: visibility 0.3s ease 0.3s;
    }
    a {
      cursor: pointer;
    }
    a > svg {
      width: 25px;
      height: 25px;
    }
  `;
customElements.define('mobile-nav-bar', MobileNavBarElement);
